import React, { useState, useRef } from 'react';
import Loader from '../Loader/Loader';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ErrorModal from '../ErrorModal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ResetLink from './ResetLink';
import Popover from 'react-bootstrap/Popover';
import { filter, find, max } from 'underscore';
import { sendNamePicked } from '../../services/sendEmail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './AddName.scss';

const AddName = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newParticipant, setNewParticipant] = useState('');
  const [newParticipantEmail, setNewParticipantEmail] = useState('');
  const [secretSanta, setSecretSanta] = useState(false);
  const [participants, setParticpants] = useState([]);
  const [grabBagComplete, setGrabBagComplete] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pricePoint, setPricePoint] = useState('');

  const inputEl = useRef(null);

  const addParticipant = (e) => {
    e.preventDefault();
    setParticpants([
      ...participants,
      {
        id: participants.length,
        email: newParticipantEmail,
        name: newParticipant,
        picked: false,
        buyingFor: null,
        emailSent: false,
        spouse: null,
      },
    ]);
    setNewParticipant('');
    setNewParticipantEmail('');
    inputEl.current.focus();
  };

  const pickNames = () => {
    setIsLoading(true);
    let newArray = [];

    participants.forEach((participant, index) => {
      setTimeout(() => {
        let person = assignPersonToBuyFor(participant);
        person.picked = true;
        participant.buyingFor = person.name;

        // send email to participant who they have selected
        if (secretSanta) {
          sendNamePicked(participant, pricePoint).then(
            (result) => {
              participant.emailSent = true;
            },
            (error) => {
              console.log('error has occured: ' + JSON.stringify(error));
            }
          );
        }
        newArray.push(participant);
      }, index * 1000); //because EmailJS rate request is 1 per second, we have to delay the loop
    });

    setParticpants(newArray);

    setTimeout(() => {
      setIsLoading(false);
      setGrabBagComplete(true);
    }, max([8000, participants.length * 1000]));
  };

  const assignPersonToBuyFor = (person) => {
    var yetToBePicked = filter(participants, (participant) => {
      return (
        participant.name !== person.name &&
        !participant.picked &&
        participant.spouse !== person.name
      );
    });

    var randomPerson =
      yetToBePicked[Math.floor(Math.random() * yetToBePicked.length)];

    // update person who was picked to flag they've been picked to avoid being picked in other draws
    var personPicked = find(participants, (participant) => {
      return participant.name === randomPerson.name;
    });

    return personPicked;
  };

  const setSpouse = (participant, spouse) => {
    participant.spouse = spouse;
    setParticpants(
      participants.map((participant, index) => {
        return participant.id === index ? participant : {};
      })
    );
  };

  const spousePopover = (
    <Popover>
      <Popover.Title as='h3'>What is this?</Popover.Title>
      <Popover.Content>
        <p>
          If you would like the generator to not choose people's significant
          others, choose them in the dropdowns next to their names. Otherwise,
          leave it blank
        </p>
      </Popover.Content>
    </Popover>
  );

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Title as='h3'>Is Secret Santa really what I want?</Popover.Title>
      <Popover.Content>
        <p>
          If you choose Secret Santa, your participants will be emailed with the
          person they are supposed to buy for and results will not be shown
          here.
        </p>
        <p>
          If you do not choose Secret Santa, you are responsible for telling
          everyone who they have to buy for.
        </p>
      </Popover.Content>
    </Popover>
  );

  const spouseList = (person) => {
    return filter(participants, (participant) => {
      return participant.name !== person.name;
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className='main container'>
      <div className='row'>
        <div className='col-md-6 mt-2'>
          <Card>
            <Card.Body>
              <div className='row mb-3'>
                <div className='col secret-santa-switcher'>
                  <Form.Check
                    type='switch'
                    id='secret-santa-switch'
                    label='Secret Santa'
                    checked={secretSanta}
                    onChange={() => setSecretSanta(!secretSanta)}
                    disabled={participants.length > 0}
                  />

                  <OverlayTrigger
                    trigger='click'
                    placement='left'
                    overlay={popover}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </div>
              </div>
              <div className='row'>
                <div className='col form-group'>
                  <Form.Group controlId='pricePointInput'>
                    <Form.Label>Price Point</Form.Label>
                    <InputGroup className='mb-2'>
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        placeholder='Enter Price Point'
                        value={pricePoint}
                        type='number'
                        onChange={(e) => setPricePoint(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 mt-2'>
          <Card>
            <Card.Body>
              <Card.Title>Add Name</Card.Title>
              <form onSubmit={addParticipant}>
                <div className='row'>
                  <div className='col form-group'>
                    <input
                      className='form-control'
                      id='participantName'
                      placeholder='Enter Name'
                      value={newParticipant}
                      onChange={(e) => setNewParticipant(e.target.value)}
                      ref={inputEl}
                      required
                    />
                  </div>

                  {secretSanta && (
                    <div className='col form-group'>
                      <input
                        className='form-control'
                        id='particiapntEmail'
                        placeholder='Enter Email'
                        value={newParticipantEmail}
                        type='email'
                        onChange={(e) => setNewParticipantEmail(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
                <ResetLink
                  linkText='Start Over'
                  setParticpants={setParticpants}
                  setGrabBagComplete={setGrabBagComplete}
                />
              </form>

              {participants.length > 0 && (
                <>
                  <div className='card-title paricipant-list'>
                    <h5>Participants</h5>

                    {grabBagComplete ? (
                      <ResetLink
                        linkText='Start Over'
                        setParticpants={setParticpants}
                        setGrabBagComplete={setGrabBagComplete}
                      />
                    ) : (
                      <a onClick={pickNames} className='btn pick-names-link'>
                        Pick Names
                      </a>
                    )}
                  </div>
                  <ListGroup variant='flush'>
                    {participants.map((participant, index) => {
                      return (
                        <ListGroup.Item
                          key={index}
                          className='row paricipant-name'
                        >
                          <div className='col participant buyer'>
                            {participant.name}
                          </div>

                          {participant.buyingFor === null && (
                            <>
                              <div className='col choose-spouse'>
                                <Form.Group controlId='exampleForm.ControlSelect1'>
                                  <Form.Control
                                    as='select'
                                    onChange={(e) =>
                                      setSpouse(participant, e.target.value)
                                    }
                                    className='spouse-select'
                                    defaultValue=''
                                  >
                                    <option disabled value=''>
                                      Significant Other
                                    </option>
                                    {spouseList(participant).map(
                                      (participant, index) => {
                                        return (
                                          <option key={index}>
                                            {participant.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                                {index === 0 && (
                                  <OverlayTrigger
                                    trigger='click'
                                    placement='top'
                                    overlay={spousePopover}
                                  >
                                    <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      className='spouse-help'
                                    />
                                  </OverlayTrigger>
                                )}
                              </div>
                            </>
                          )}

                          {participant.buyingFor && (
                            <>
                              <span className='col'>is buying for </span>
                              <span className='col participant buying-for'>
                                {participant.emailSent
                                  ? "Shhhh... It's a secret!"
                                  : participant.buyingFor}
                              </span>
                            </>
                          )}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>

      {errorModalOpen && (
        <ErrorModal
          errorModalOpen={errorModalOpen}
          setErrorModalOpen={setErrorModalOpen}
        />
      )}
    </div>
  );
};

export default AddName;
