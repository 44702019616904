import './App.css';
import AddName from './components/AddName/AddName';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <h2>Christmas Grab Bag Generator</h2>
      </header>

      <div>
        <AddName />
      </div>
    </div>
  );
}

export default App;
