import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ErrorModal.scss';

const ErrorModal = ({ errorModalOpen, setErrorModalOpen }) => {
  return (
    <Modal show={errorModalOpen}>
      <Modal.Body className='error-message-body'>
        <img src='sad-elf-emoji.png' alt='error-img' />
        <span>
          You don't have an even amount of participants. Someone is going to be
          left out.. Can't have that now can we?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setErrorModalOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
