import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div id='candy-loader-main'>
      <div id='candy'>
        <div id='candy-loader'></div>
      </div>
    </div>
  );
};

export default Loader;
