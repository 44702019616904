import React from 'react';

const ResetLink = ({ linkText, setParticpants, setGrabBagComplete }) => {
  return (
    <a
      href=''
      className='btn'
      onClick={(e) => {
        e.preventDefault();
        setParticpants([]);
        setGrabBagComplete(false);
      }}
    >
      {linkText}
    </a>
  );
};

export default ResetLink;
