import emailJS from 'emailjs-com';

export const sendNamePicked = (data, pricePoint) => {
  return emailJS.send(
    'service_k9xy1xu',
    'template_a5ul8i3',
    {
      to_email: data.email,
      to_name: data.name,
      buying_for: data.buyingFor,
      price_point: pricePoint,
    },
    'user_YgqwdRE3cVm3IHHVgkBRR'
  );
};
